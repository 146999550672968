<template>
  <!-- <HelloWorld msg="Welcome to Your Vue.js App" /> -->
  <v-container class="px-16 blue-grey lighten-5 margin-app-bar v-container bg-blue-grey-lighten-5">
    <v-row>
      <v-col cols="12" md="5">
        <!-- ENCABEZADO DE BUSQUEDA -->
        <v-row>
          <v-col cols="12" class="">
            <div
              class="text-red pb-10 text-h6 text-md-h5 text-lg-h4 text-xl-h2 text-left font-weight-bold"
            >
              MONITOREO DE PILAS
            </div>
            <div class="text-grey text-4 text-left font-weight-bold">
              SELECCIONE EL NUMERO DE PILA
            </div>
            <v-select
              v-model="selectedPilas"
              :items="pilas"
              label="Seleccione una opción"
              solo
              hide-details="true"
            ></v-select>
          </v-col>
        </v-row>
        <!-- ENCABEZADO DE BUSQUEDA -->

        <!-- FECHA -->
        <v-row class="align-center ">
          <v-col md="4" sm="4" xs="12">
            <v-select
              v-model="selectedAnio"
              :items="anios"
              label="Año"
              solo
              hide-details="true"
              @change="onValueSelected"
            ></v-select>
          </v-col>

          <v-col md="4" sm="4" xs="12">
            <v-select
              v-model="selectedMes"
              :items="meses"
              label="Mes"
              solo
              hide-details="true"
            ></v-select>
          </v-col>

          <v-col md="4" sm="4" xs="12">
            <v-select
              v-model="selectedDia"
              :items="dias"
              label="Día"
              solo
              hide-details="true"
            ></v-select>
          </v-col>
        </v-row>
        <!-- FECHA -->

        <!-- ESTADOS -->
        <v-row>
          <v-col cols="12" sm="6">
            <v-card tile class="bg-red square-border">
              <v-card-text
                class="text-sm-body-2 text-md-body-1 text-lg-h6 white--text text-center"
                >Fase</v-card-text
              >
            </v-card>
            <v-card
              height="200px"
              class="white--text square-border d-flex align-center justify-center bg-green"
            >
              <v-card-text v-if="selectedMes" class="text-center align-center">
                <div class="text-sm-body-2 text-md-body-1 text-lg-h4 text-uppercase">
                  {{ estado_fase }}
                </div>
              </v-card-text>
              <v-card-text v-else class="text-center align-center">
                Selecciona una fecha para ver la información
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" sm="6">
            <v-card tile class="bg-red square-border">
              <v-card-text
                class="text-sm-body-2 text-md-body-1 text-lg-h6 white--text text-center"
              >
                Situación Actual
              </v-card-text>
            </v-card>
            <v-card
              tile
              height="200px"
              class="white--text square-border d-flex align-center justify-center"
            >
              <v-card-text v-if="estado_fase">
                <v-img contain :src="rutaFase" max-width="auto"></v-img>
              </v-card-text>
              <v-card-text v-else>
                Selecciona una fecha para ver la información
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6">
            <v-card tile class="bg-red square-border">
              <v-card-text
                class="text-sm-body-2 text-md-body-1 text-lg-h6 white--text text-center"
                >Humedad %</v-card-text
              >
            </v-card>
            <v-card
              tile
              height="200px"
              class="white--text square-border d-flex align-center justify-center"
            >
              <v-card-text v-if="estado_humedad">
                <div class="text-h2" :class="estado_humedad_color">
                  {{ estado_humedad }}%
                </div>
              </v-card-text>
              <v-card-text v-else>
                Selecciona una fecha para ver la información
              </v-card-text>
            </v-card>
            <!-- <v-img
              contain
              src="@/assets/img/EnfriamientoHumedad.png"
              max-width="auto"
            ></v-img> -->
            <v-img contain :src="rutaHumedad" max-width="auto"></v-img>
          </v-col>
          <v-col cols="12" sm="6">
            <v-card tile class="bg-red square-border">
              <v-card-text class="text-sm-body-2 text-md-body-1 text-lg-h6 text-center"
                >Temperatura °C</v-card-text
              >
            </v-card>
            <v-card
              tile
              height="200px"
              class="white--text square-border d-flex align-center justify-center"
            >
              <v-card-text v-if="estado_temperatura">
                <div class="text-h2" :class="estado_temperatura_color">
                  {{ estado_temperatura }}°
                </div>
              </v-card-text>
              <v-card-text v-else>
                Selecciona una fecha para ver la información
              </v-card-text>
            </v-card>
            <!-- <v-img
              contain
              src="@/assets/img/EnfriamientoTemperatura.png"
              max-width="auto"
            ></v-img> -->
            <v-img contain :src="rutaTemperatura" max-width="auto"></v-img>
          </v-col>
        </v-row>
        <!-- ESTADOS -->
        <!--  -->
      </v-col>

      <v-col cols="12" md="7" class="">
        <v-img
          src="@/assets/img/EsquemaCompost.png"
          max-height="800"
          max-width="800"
        ></v-img>
        <!-- contain -->
      </v-col>
    </v-row>

    <!-- FASES DEL COMPOST -->
    <v-row>
      <v-col cols="12" sm="6" md="3">
        <v-card tile class="bg-green text-center square-border d-flex align-center justify-center" height="80px">
          <div class="text-sm-body-2 text-md-body-1 text-lg-h6 white--text">
            Fase Mesofílica
          </div>
        </v-card>

        <v-card tile height="300px" class="overflow-auto square-border">
          <v-card-text>
            En la fase Inicial comienza la multiplicación y colonización de
            microorganismos en los residuos.
            <br /><br /><strong>Temperatura</strong>: inicia entre 20˚C – 40˚C, y puede
            llegar a alcanzar hasta 70˚C. <br /><br /><strong>Duración</strong>: entre 2 y
            8 días.
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-card tile class="bg-green text-center square-border d-flex align-center justify-center" height="80px">
          <div class="text-sm-body-2 text-md-body-1 text-lg-h6 white--text">
            Fase Higienización (Termofílica)
          </div>
        </v-card>
        <v-card tile height="300px" class="overflow-auto square-border">
          <v-card-text>
            Los microorganismos iniciales son sustituidos por microorganismos termófilos,
            que actúan a una alta temperatura, produciéndose una rápida degradación del
            material añadido a la pila. La mezcla se debe airear frecuentemente con el
            objetivo de aportar oxígeno a los microorganismos para que se descompongan.
            <br /><br /><strong>Temperatura</strong>: desde 45˚C <br /><br /><strong
              >Duración</strong
            >: desde unos días hasta varios meses.
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-card tile class="bg-green text-center square-border d-flex align-center justify-center" height="80px">
          <div class="text-sm-body-2 text-md-body-1 text-lg-h6 white--text align-center">
            Fase de Enfriamiento (Mesófila II)
          </div>
        </v-card>
        <v-card tile height="300px" class="overflow-auto square-border">
          <v-card-text>
            Al transformarse prácticamente la totalidad de la materia orgánica, la
            temperatura comienza a descender. Aquí habrá que decidir si se voltea la
            mezcla para homogeneizarla y elevar la temperatura de nuevo o dejar que la
            temperatura descienda.
            <br /><br /><strong>Temperatura</strong>: entre 40˚C – 45˚C.
            <br /><br /><strong>Duración</strong>: desde unos días hasta 2 meses.
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-card tile class="bg-green text-center square-border d-flex align-center justify-center" height="80px">
          <div class="text-sm-body-2 text-md-body-1 text-lg-h6 white--text">
            Fase de Maduración (Fase Final)
          </div>
        </v-card>
        <v-card tile height="300px" class="overflow-auto square-border">
          <v-card-text>
            Esta última fase corresponde a una fermentación lenta, donde la parte menos
            biodegradable de la materia orgánica es degradada. La mezcla se enfría hasta
            llegar a temperatura ambiente.
            <br /><br /><strong>Temperatura</strong>: entre 20˚C – 40˚C.
            <br /><br /><strong>Duración</strong>: 3 a 9 meses. (Para estos textos dejar
            la caja del tamaño del texto más pequeño y las otras que tengan scroll para
            ver el texto que queda oculto)
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <br>
    <br>
    <!-- FASES DEL COMPOST -->
  </v-container>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";
import axios from "axios";
export default {
  name: "HomeView",
  // components: {
  // HelloWorld,
  // },
  data() {
    return {
      pilas: [],
      pilas_objeto: [],
      dias: [
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
        31,
      ],
      meses: [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ],
      anios: [],
      auxiliar: [],
      estado_fase: null,
      estado_temperatura: null,
      estado_temperatura_color: null,
      estado_humedad: null,
      estado_humedad_color: null,
      estado_apariencia: null,
      rutaFase: null,
      rutaHumedad: null,
      rutaTemperatura: null,
      estado_color: null,
      selectedDia: null,
      selectedMes: null,
      selectedAnio: null,
      selectedPilas: null,
    };
  },
  mounted() {
    this.getSheetData();
    // console.log("Componente HomeView montado correctamente");
  },
  computed: {
    estadoColor() {
      return `bg-${this.estado_color}`;
    },
  },
  watch: {
    selectedPilas() {
      const pila = this.selectedPilas;
      console.log(pila);
      console.log(this.pilas_objeto);
      this.filtraAnios(pila);
    },
    selectedAnio() {
      const anio = this.selectedAnio;
      const pila = this.selectedPilas;
      this.filtraMeses(anio, pila);
    },
    selectedMes() {
      const mes = this.selectedMes;
      const anio = this.selectedAnio;
      const pila = this.selectedPilas;
      this.filtraDias(mes, anio, pila);
    },
    selectedDia() {
      const pila = this.selectedPilas;
      const anio = this.selectedAnio;
      const mes = this.selectedMes;
      const dia = this.selectedDia;
      this.filtraInformacion(pila, anio, mes, dia);
    },
  },
  methods: {
    filtraAnios(pila) {
      const valoresUnicos = [...new Set(Object.values(this.pilas_objeto))];
      const objetoSinDuplicados_anios = {};
      for (const [clave, valor] of Object.entries(this.pilas_objeto)) {
        if (valor[1] == pila) {
          console.log(pila);
          if (valor[11]) {
            const fechaString = valor[11]; // fecha en formato 'dd/mm/aaaa'
            const partesFecha = fechaString.split("/"); // separar la cadena de fecha en partes: día, mes, año
            const fecha = new Date(partesFecha[2], partesFecha[1] - 1, partesFecha[0]); // crear la fecha utilizando las partes separadas
            if (fecha !== undefined || fecha !== "" || fecha !== NaN) {
              if (valoresUnicos.includes(valor)) {
                objetoSinDuplicados_anios[clave] = partesFecha[2];
                valoresUnicos.splice(valoresUnicos.indexOf(valor), 1);
              }
            }
          }
        }
      }
      this.anios = Array.from(new Set(Object.values(objetoSinDuplicados_anios)));
    },
    filtraMeses(anio, pila) {
      const valoresUnicos = [...new Set(Object.values(this.pilas_objeto))];
      const objetoSinDuplicados_meses = {};
      for (const [clave, valor] of Object.entries(this.pilas_objeto)) {
        if (valor[11]) {
          console.log(valor[11]);
          console.log(anio);
          const fechaString = valor[11]; // fecha en formato 'dd/mm/aaaa'
          const partesFecha = fechaString.split("/"); // separar la cadena de fecha en partes: día, mes, año
          const fecha = new Date(partesFecha[2], partesFecha[1] - 1, partesFecha[0]); // crear la fecha utilizando las partes separadas

          if (fecha !== undefined || fecha !== "" || fecha !== NaN) {
            if (partesFecha[2] === anio && valor[1] === pila) {
              if (valoresUnicos.includes(valor)) {
                objetoSinDuplicados_meses[clave] = partesFecha[1];
                valoresUnicos.splice(valoresUnicos.indexOf(valor), 1);
              }
            }
          }
        }
      }
      this.meses = Array.from(new Set(Object.values(objetoSinDuplicados_meses)));
    },
    filtraDias(mes, anio, pila) {
      const valoresUnicos = [...new Set(Object.values(this.pilas_objeto))];
      const objetoSinDuplicados_dias = {};
      for (const [clave, valor] of Object.entries(this.pilas_objeto)) {
        if (valor[11]) {
          const fechaString = valor[11]; // fecha en formato 'dd/mm/aaaa'
          const partesFecha = fechaString.split("/"); // separar la cadena de fecha en partes: día, mes, año
          const fecha = new Date(partesFecha[2], partesFecha[1] - 1, partesFecha[0]); // crear la fecha utilizando las partes separadas

          if (fecha !== undefined || fecha !== "" || fecha !== NaN) {
            console.log(partesFecha[1], partesFecha[2]);
            console.log(mes, anio);
            if (partesFecha[1] === mes && partesFecha[2] === anio && valor[1] === pila) {
              if (valoresUnicos.includes(valor)) {
                objetoSinDuplicados_dias[clave] = partesFecha[0];
                // this.auxiliar.push(valor);
                valoresUnicos.splice(valoresUnicos.indexOf(valor), 1);
              }
            }
          }
        }
      }
      this.dias = Array.from(new Set(Object.values(objetoSinDuplicados_dias)));
    },
    filtraInformacion(pila, anio, mes, dia) {
      const valoresUnicos = [...new Set(Object.values(this.pilas_objeto))];
      const objetoSinDuplicados_informacion = {};
      for (const [clave, valor] of Object.entries(this.pilas_objeto)) {
        if (valor[11]) {
          const fechaString = valor[11]; // fecha en formato 'dd/mm/aaaa'
          const partesFecha = fechaString.split("/"); // separar la cadena de fecha en partes: día, mes, año
          const fecha = new Date(partesFecha[2], partesFecha[1] - 1, partesFecha[0]); // crear la fecha utilizando las partes separadas

          if (fecha !== undefined || fecha !== "" || fecha !== NaN) {
            if (
              partesFecha[0] === dia &&
              partesFecha[1] === mes &&
              partesFecha[2] === anio &&
              valor[1] === pila
            ) {
              if (valoresUnicos.includes(valor)) {
                objetoSinDuplicados_informacion[clave] = valor;
                this.auxiliar.push(valor);
                console.log(valor);
                this.estado_temperatura = valor[12];
                this.estado_humedad = valor[13];
                this.estado_apariencia = valor[14];
                this.estado_fase = valor[19];
                // CARGA LA IMAGEN SEGÚN LA FASE EN QUE SE ENCUENTRE
                if (this.estado_fase === "Mesofílica") {
                  this.rutaFase = require("@/assets/img/fases/FaseMesofilica.jpg");
                  this.rutaHumedad = require("@/assets/img/fases/MesofilicaHumedad.png");
                  this.rutaTemperatura = require("@/assets/img/fases/MesofilicaTemperatura.png");
                  if (this.estado_temperatura >= 20 && this.estado_temperatura <= 70) {
                    this.estado_temperatura_color = "text-green";
                  } else if (this.estado_temperatura < 20) {
                    this.estado_temperatura_color = "text-red";
                  } else if (this.estado_temperatura > 70) {
                    this.estado_temperatura_color = "text-red";
                  }
                  if (this.estado_humedad >= 30 && this.estado_humedad <= 70) {
                    this.estado_humedad_color = "text-green";
                  } else if (this.estado_humedad < 20) {
                    this.estado_humedad_color = "text-red";
                  } else if (this.estado_humedad > 70) {
                    this.estado_humedad_color = "text-red";
                  }
                } else if (this.estado_fase === "Termofílica") {
                  this.rutaFase = require("@/assets/img/fases/FaseTermofilica.jpg");
                  this.rutaHumedad = require("@/assets/img/fases/TermofilicaHumedad.png");
                  this.rutaTemperatura = require("@/assets/img/fases/TermofilicaTemperatura.png");
                  if (this.estado_temperatura >= 45 && this.estado_temperatura <= 70) {
                    this.estado_temperatura_color = "text-green";
                  } else if (this.estado_temperatura < 45) {
                    this.estado_temperatura_color = "text-red";
                  } else if (this.estado_temperatura > 70) {
                    this.estado_temperatura_color = "text-red";
                  }
                  if (this.estado_humedad >= 50 && this.estado_humedad <= 70) {
                    this.estado_humedad_color = "text-green";
                  } else if (this.estado_humedad < 50) {
                    this.estado_humedad_color = "text-red";
                  } else if (this.estado_humedad > 70) {
                    this.estado_humedad_color = "text-red";
                  }
                } else if (this.estado_fase === "Enfriamiento") {
                  this.rutaFase = require("@/assets/img/fases/FaseEnfriamiento.jpg");
                  this.rutaHumedad = require("@/assets/img/fases/EnfriamientoHumedad.png");
                  this.rutaTemperatura = require("@/assets/img/fases/EnfriamientoTemperatura.png");
                  if (this.estado_temperatura >= 40 && this.estado_temperatura <= 45) {
                    this.estado_temperatura_color = "text-green";
                  } else if (this.estado_temperatura < 40) {
                    this.estado_temperatura_color = "text-red";
                  } else if (this.estado_temperatura > 45) {
                    this.estado_temperatura_color = "text-red";
                  }
                  if (this.estado_humedad >= 50 && this.estado_humedad <= 70) {
                    this.estado_humedad_color = "text-green";
                  } else if (this.estado_humedad < 50) {
                    this.estado_humedad_color = "text-red";
                  } else if (this.estado_humedad > 70) {
                    this.estado_humedad_color = "text-red";
                  }
                } else if (this.estado_fase === "Maduración") {
                  this.rutaFase = require("@/assets/img/fases/FaseMaduracion.jpg");
                  this.rutaHumedad = require("@/assets/img/fases/MadururacionHumedad.png");
                  this.rutaTemperatura = require("@/assets/img/fases/MaduracionTemperatura.png");
                  if (this.estado_temperatura >= 20 && this.estado_temperatura <= 40) {
                    this.estado_temperatura_color = "text-green";
                  } else if (this.estado_temperatura < 20) {
                    this.estado_temperatura_color = "text-red";
                  } else if (this.estado_temperatura > 40) {
                    this.estado_temperatura_color = "text-red";
                  }
                  if (this.estado_humedad >= 50 && this.estado_humedad <= 70) {
                    this.estado_humedad_color = "text-green";
                  } else if (this.estado_humedad < 50) {
                    this.estado_humedad_color = "text-red";
                  } else if (this.estado_humedad > 70) {
                    this.estado_humedad_color = "text-red";
                  }
                }
                // CARGA LA IMAGEN SEGÚN LA FASE EN QUE SE ENCUENTRE

                valoresUnicos.splice(valoresUnicos.indexOf(valor), 1);
              }
            }
          }
        }
      }
      // this.dias = Array.from(new Set(Object.values(objetoSinDuplicados_informacion)));
      console.log(Array.from(new Set(Object.values(objetoSinDuplicados_informacion))));
    },
    onValueSelected() {
      console.log("Pila seleccionada: " + this.selectedPilas);
      console.log("Día seleccionado: " + this.selectedDia);
      console.log("Mes seleccionado: " + this.selectedMes);
      console.log("Año seleccionado: " + this.selectedAnio);
    },
    async getSheetData() {
      const apiKey = "AIzaSyDfac1sNAz1T33aQ4XhqkEZfMAF81M_9LU";
      const sheetId = "19XAHuvSMCbmHqoiQrWUyy-uxNQEwegLS9dHsin1Cxes";
      const sheetName = "2022!A1:T";
      const url = `https://sheets.googleapis.com/v4/spreadsheets/${sheetId}/values/${sheetName}?key=${apiKey}`;
      try {
        const response = await axios.get(url);

        for (let index = 1; index < response.data.values.length; index++) {
          this.pilas.push(response.data.values[index]);
          this.pilas_objeto.push(response.data.values[index]);
        }

        const valoresUnicos = [...new Set(Object.values(this.pilas))];
        const objetoSinDuplicados = {};
        for (const [clave, valor] of Object.entries(this.pilas)) {
          if (valor[11]) {
            const fechaString = valor[11]; // fecha en formato 'dd/mm/aaaa'
            const partesFecha = fechaString.split("/"); // separar la cadena de fecha en partes: día, mes, año
            const fecha = new Date(partesFecha[2], partesFecha[1] - 1, partesFecha[0]); // crear la fecha utilizando las partes separadas
            if (fecha !== undefined || fecha !== "" || fecha !== NaN) {
              if (valoresUnicos.includes(valor)) {
                objetoSinDuplicados[clave] = valor[1];
                valoresUnicos.splice(valoresUnicos.indexOf(valor), 1);
              }
            }
          }
        }

        // const valoresUnicos_dias = [...new Set(Object.values(this.pilas))];
        // const objetoSinDuplicados_dias = {};
        // for (const [clave, valor] of Object.entries(this.pilas)) {
        //   if (valor[11]) {
        //     const fechaString = valor[11]; // fecha en formato 'dd/mm/aaaa'
        //     const partesFecha = fechaString.split("/"); // separar la cadena de fecha en partes: día, mes, año
        //     const fecha = new Date(partesFecha[2], partesFecha[1] - 1, partesFecha[0]); // crear la fecha utilizando las partes separadas
        //     if (fecha !== undefined || fecha !== "" || fecha !== NaN) {
        //       if (valoresUnicos_dias.includes(valor)) {
        //         objetoSinDuplicados_dias[clave] = fecha.getDate();
        //         valoresUnicos_dias.splice(valoresUnicos_dias.indexOf(valor), 1);
        //       }
        //     }
        //   }
        // }

        // const valoresUnicos_meses = [...new Set(Object.values(this.pilas))];
        // const objetoSinDuplicados_meses = {};
        // for (const [clave, valor] of Object.entries(this.pilas)) {
        //   if (valor[11]) {
        //     const fechaString = valor[11]; // fecha en formato 'dd/mm/aaaa'
        //     const partesFecha = fechaString.split("/"); // separar la cadena de fecha en partes: día, mes, año
        //     const fecha = new Date(partesFecha[2], partesFecha[1] - 1, partesFecha[0]); // crear la fecha utilizando las partes separadas
        //     if (fecha !== undefined || fecha !== "" || fecha !== NaN) {
        //       if (valoresUnicos_meses.includes(valor)) {
        //         objetoSinDuplicados_meses[clave] = fecha.getMonth();
        //         valoresUnicos_meses.splice(valoresUnicos_meses.indexOf(valor), 1);
        //       }
        //     }
        //   }
        // }

        // const valoresUnicos_anios = [...new Set(Object.values(this.pilas))];
        // const objetoSinDuplicados_anios = {};
        // for (const [clave, valor] of Object.entries(this.pilas)) {
        //   if (valor[11]) {
        //     const fechaString = valor[11]; // fecha en formato 'dd/mm/aaaa'
        //     const partesFecha = fechaString.split("/"); // separar la cadena de fecha en partes: día, mes, año
        //     const fecha = new Date(partesFecha[2], partesFecha[1] - 1, partesFecha[0]); // crear la fecha utilizando las partes separadas
        //     if (fecha !== undefined || fecha !== "" || fecha !== NaN) {
        //       if (valoresUnicos_anios.includes(valor)) {
        //         objetoSinDuplicados_anios[clave] = fecha.getFullYear();
        //         valoresUnicos_anios.splice(valoresUnicos_anios.indexOf(valor), 1);
        //       }
        //     }
        //   }
        // }

        this.pilas = Array.from(new Set(Object.values(objetoSinDuplicados)));

        // this.dias = Array.from(new Set(Object.values(objetoSinDuplicados_dias)));

        // this.meses = Array.from(new Set(Object.values(objetoSinDuplicados_meses)));

        // this.anios = Array.from(new Set(Object.values(objetoSinDuplicados_anios)));

        this.pilas.sort(function (a, b) {
          return a - b;
        });
        // this.dias.sort(function (a, b) {
        //   return a - b;
        // });
        // this.meses.sort(function (a, b) {
        //   return a - b;
        // });
        // this.anios.sort(function (a, b) {
        //   return a - b;
        // });
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.square-border {
  border-radius: 0;
}
.v-container {
  max-width: 1600px;
}
.margin-app-bar {
  margin-top: 94px; /* 64px es la altura por defecto del v-app-bar */
}
.v-card {
  width: 100%;
}

h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
