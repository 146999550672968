<template>
  <v-app>
    <v-app-bar app color="#86B626" class="app-bar">
      <v-container>
        <v-row>
          <v-col cols="2">
            <v-img src="@/assets/img/logosurfrut.png" height="50px" contain></v-img>
          </v-col>
          <v-col cols="10" class="d-flex">
            <v-row class="align-center" justify="end">
              <v-btn text color="#236E39" class="font-weight-bold" to="/"
                >Monitoreo</v-btn
              >
              <v-btn text color="#236E39" class="font-weight-bold" to="/about"
                >Compostaje</v-btn
              >
              <v-btn text color="#236E39" class="font-weight-bold" to="/contact"
                >Contacto</v-btn
              >
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-app-bar>

    <router-view />

    <v-footer class="d-flex flex-column footer-bottom">
      <div class="d-flex w-100 align-center px-4 text-5">
        &copy; 2023 - Av. Ramón Freire 1390, Romeral, Curicó, Chile/Phone: + 56 75 243
        1334
        <v-spacer></v-spacer>
        <v-icon>mdiAccount</v-icon>
        <!-- <v-btn class="mx-4" :icon="mdiAccount" size="small"></v-btn> -->
      </div>
    </v-footer>
  </v-app>
</template>

<script>
// import axios from "axios";
export default {
  data: () => {
    return {
      icons: ["mdiAccounts"],
      selectedDia: null,
      selectedMes: null,
      selectedAnio: null,
    };
  },
  mounted() {
    // this.getSheetData();

  },
  created() {
    document.title = 'Surfrut | Compost';
  },
  methods: {
    obtenerRegistroMasCercanoAUnaFecha(fechaDada, objeto) {
      // Paso 1: parsear la fecha dada a un objeto Date
      const fechaDadaObj = new Date(fechaDada);

      // Paso 2: inicializar la distancia más pequeña y el índice del objeto con la fecha más cercana
      let distanciaMasPequena = Infinity;
      let indiceRegistroMasCercano = -1;

      // Paso 3: iterar a través de los objetos en el array
      for (let i = 1; i < objeto.length; i++) {
        // Paso 4: parsear la fecha correspondiente del objeto a un objeto Date
        const fechaRegistroObj = new Date(objeto[i][4]);

        // Paso 6: calcular la distancia entre las dos fechas
        const distanciaActual = Math.abs(fechaDadaObj - fechaRegistroObj);

        // Paso 7: actualizar la distancia más pequeña y el índice del objeto con la fecha más cercana
        if (distanciaActual < distanciaMasPequena) {
          distanciaMasPequena = distanciaActual;
          indiceRegistroMasCercano = i;
        }
      }

      // Paso 8: devolver el objeto en el índice almacenado en la variable de índice del objeto con la fecha más cercana
      return objeto[indiceRegistroMasCercano];
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.footer-bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}
</style>
